import React from 'react'
import { withRouter } from "react-router";
import ReactGA from 'react-ga';

class ChangePage extends React.Component {
  componentDidUpdate(prevProps) {
    // Scroll to top on page change.
    if (this.props.location !== prevProps.location) {
      // Track page view.
      let options = JSON.parse(localStorage.getItem("options"));
      if (options.statistics === true) {
          ReactGA.pageview(window.location.pathname)
      }
      // Save current page view for iOS.
      localStorage.setItem("options", JSON.stringify({...options, ...{'last_page': this.props.location.pathname}}))
      // Scroll to top.
      window.scrollTo(0, 0)
      // Manager burger menu.
      const burger_menu = document.getElementById('burgerMenu')
      burger_menu.className = 'burgerIcon'
      burger_menu.setAttribute('attr-open', 0);
      document.getElementById('mainMenu').className = 'main-menu closed'
      // Give focus to blank element.
      document.getElementById('focus_content').focus()
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ChangePage)
