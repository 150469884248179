import React from 'react';
import ReactGA from 'react-ga';
/**
 * User guide pages.
 */
class PrescriptionQuestionComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      'open': true,
    }
    this.toggleContainer = this.toggleContainer.bind(this)
    this.hideContainer = this.hideContainer.bind(this)
  }
  /**
   * On load, get option value to display or not this pane.
   */
  componentDidMount() {
    let options = JSON.parse(localStorage.getItem("options"))
    this.setState({
      open: options.display_prescription_question
    })
  }
  /**
   * Hide pane, and save action to user options.
   */
  hideContainer(event) {
    let options = JSON.parse(localStorage.getItem("options"))
    options.display_prescription_question = false
    localStorage.setItem("options", JSON.stringify(options))

    this.setState({
      open: false
    })

    if (options.statistics === true) {
      ReactGA.event({
        category: 'Prescription-Question',
        action: 'HideByDefault',
        label: 'HideByDefault'
      });
    }
  }
  /**
   * Hide / display pane.
   */
  toggleContainer(event) {
    let options = JSON.parse(localStorage.getItem("options"))
    if (options.statistics === true) {
      ReactGA.event({
        category: 'Prescription-Question',
        action: 'Toogle',
        label: (this.state.open) ? 'Close' : 'Open'
      });
    }

    this.setState({
      open: !this.state.open
    })
  }
  /**
   * Render HTML.
   */
  render() {
    const containerClass = (this.state.open) ? 'question-container question-container-open' : 'question-container question-container-close'
    const displayAction = (this.state.open) ? 'question-container-display question-container-display-open' : 'question-container-display question-container-display-close'

    return (
      <div className={containerClass}>
        <div className="question-container-title">
          <h3>
            Les deux questions à se poser avant de rédiger la prescription&nbsp;:<br />
            <span className="question-container-subtitle">(sauf si soins non remboursés)</span>
          </h3>
          <button className={displayAction} onClick={this.toggleContainer} />
        </div>
        <div className="question-container-content">
          <div className="question-container-content-inner">
            <ol>
              <li>
                Mon patient bénéficie-t-il d'une prise en charge en ALD en cours de validité ?
              </li>
              <li>
                Ma prescription relève-t-elle d'une ALD ?<br />
              </li>
            </ol>
            <p>
              Si oui, ordonnance bizone ou préciser "ALD"
            </p>
            <button className="hide-default-message" onClick={this.hideContainer}>
              Masquer par défaut ce message.
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default PrescriptionQuestionComponent;
