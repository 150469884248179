import React from 'react';
import { NavLink } from "react-router-dom";

/**
 * Buger Menu
 */
 class MainMenuComponent extends React.Component {

   render() {
     const mainMenuClass = (this.props.isOpen) ? 'main-menu open' : 'main-menu closed'
     // Load user guides links.
     const user_guides = JSON.parse(localStorage.getItem('user_guides_menu'))
     return (
       <div id="mainMenu" className={mainMenuClass}>
         <ul>
           <li>
             <NavLink activeClassName='is-active' to="/mode-emploi">
               Mode d'emploi
             </NavLink>
             <ul>
               {
                 user_guides.map((object, i) => {
                   const url = `/mode-emploi/${object.path}`
                   return (
                     <React.Fragment key={i}>
                       <li>
                         <NavLink activeClassName='is-active' to={url}>{object.title}</NavLink>
                       </li>
                     </React.Fragment>
                   )
                 })
               }
             </ul>
           </li>
           <li className="userguide_first">
             <NavLink activeClassName='is-active' to="/prescriptions">
               Prescriptions
             </NavLink>
             <ul>
               <li>
                 <NavLink activeClassName='is-active' to="/prescriptions/favoris">
                   Favoris
                 </NavLink>
               </li>
             </ul>
           </li>
           <li className="userguide_first">
             <NavLink activeClassName='is-active' to="/illustrations">
               Illustrations
             </NavLink>
           </li>
           <li>
             <NavLink activeClassName='is-active' to="/parametres">
               Paramètres
             </NavLink>
           </li>
         </ul>
       </div>
     );
   }
 }

export default MainMenuComponent;
