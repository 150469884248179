import React from 'react';
import ReactGA from 'react-ga';
/**
 * User guide pages.
 */
class PrescriptionBookmarkComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      bookmarked: false,
    }
    this.toogleBookmark = this.toogleBookmark.bind(this)
  }
  /**
   * On load, Check if prescription is bookmarked.
   */
  componentDidMount() {
    let options = JSON.parse(localStorage.getItem("options"))
    // Check if prescription is bookmarked.
    var index = options.bookmarks.indexOf(this.props.pid);
    if (index > -1) {
      this.setState({
        bookmarked: true
      })
    }
  }
  /**
   * Toogle bookmark
   */
  toogleBookmark() {
    let options = JSON.parse(localStorage.getItem("options"))
    const { bookmarked } = this.state
    // Search if prescription is bookmarked.
    var index = options.bookmarks.indexOf(this.props.pid);
    if (index > -1 && bookmarked) {
      // Remove bookmark.
      options.bookmarks.splice(index, 1);
      try {
        // Remove bookmark from list.
        const list = document.getElementById('prescriptions-list');
        if (list.getAttribute('attr-bookmarks') === '1') {
          const list_element = `prescription-${this.props.pid}`
          document.getElementById(list_element).className = 'hide'
        }
      } catch(e) {

      }
    }
    else {
      // Add bookmark.
      options.bookmarks.push(this.props.pid)
      // Reload bookmark from list.
      try {
        const list = document.getElementById('prescriptions-list');
        if (list.getAttribute('attr-bookmarks') === '1') {
          const list_element = `prescription-${this.props.pid}`
          document.getElementById(list_element).className = ''
        }
      } catch(e) {

      }

    }
    // Give focus to blank element.
    document.getElementById('focus_content').focus()

    // Track GA event.
    let user_options = JSON.parse(localStorage.getItem("options"))
    if (user_options.statistics === true) {
      ReactGA.event({
        category: 'Bookmarks',
        action: (bookmarked) ? 'Delete' : 'Create',
        label: this.props.ptitle
      });
    }
    // Save bookmarks.
    localStorage.setItem("options", JSON.stringify(options))
    this.setState({
      bookmarked: !bookmarked
    })
  }
  /**
   * Check if prescription is bookmared.
   */
  isBookmarked() {
    let options = JSON.parse(localStorage.getItem("options"))
    // Check if prescription is bookmarked.
    var index = options.bookmarks.indexOf(this.props.pid);
    if (index > -1) {
      return true
    }
    return false
  }
  /**
   * Render HTML.
   */
  render() {
    const bookmarked = this.isBookmarked()
    const bookmarkedClass = (bookmarked) ? 'prescription-bookmark prescription-bookmark-bookmarked' : 'prescription-bookmark'
    return (
      <button className={bookmarkedClass} onClick={this.toogleBookmark}></button>
    )
  }
}

export default PrescriptionBookmarkComponent;
