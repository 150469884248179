import React from 'react';

/**
 * Buger Menu
 */
 class BurgerMenuComponent extends React.Component {
   render() {
     const burgerMenuClass = (this.props.isOpen) ? 'burgerIcon open' : 'burgerIcon'
     const attr = (this.props.isOpen) ? '1' : '0'
     return (
       <div id="burgerMenu" className={burgerMenuClass} attr-open={attr}>
         <span className="bar"></span>
         <span className="bar"></span>
       </div>
     );
   }
 }

export default BurgerMenuComponent;
