import React from 'react';
/**
 * User guide pages.
 */
function AmValidatioDateComponent( props ) {
  if (props.date) {
    return (
      <div className="prescription-validation-am">Validée par l'Assurance Maladie le { props.date.substring(8,10) }/{ props.date.substring(5,7) }/{ props.date.substring(0,4) }.</div>
    );
  }
  else {
    return <React.Fragment />
  }
}

export default AmValidatioDateComponent;
