import React from 'react';
import { Link } from 'react-router-dom'
import PrescriptionsComponent from '../../components/prescriptions'
import PrescriptionComponent from '../../components/prescription'
// import { check_userguide_reading } from '../../../utils/userguide'
class PrescriptionsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  /**
   * Render HTML.
   */
  render() {
    let prescription = false
    let bookmarks = false
    let id = false
    const props = this.props

    if (props.match) {
      // Display defined prescription ?
      id = props.match.params.id
      // Bookmarks case.
      if (id !== 'favoris') {
          prescription = props.match.params.id
      }
      else {
        bookmarks = true
      }
      if (props.match.params.favoris !== undefined) {
        bookmarks = true
      }
    }
    // check_userguide_reading()
    const options = JSON.parse(localStorage.getItem("options"))
    //
    // Render full display.
    //
    return (
      <div id="prescriptions-container" className="prescriptions-container">
        <div className="prescriptions-title-container">
          <h1 className="prescriptions-title">Prescriptions</h1>
        </div>
        { options.userguide !== true &&
          <div id="user-guide-reminder" className="user-guide-reminder">
            <Link to="/mode-emploi">
              Pensez à lire le  mode d'emploi avant toute première utilisation.
            </Link>
          </div>
        }
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 order-lg-2">
              <PrescriptionComponent prescription={prescription} />
            </div>
            <div className="col-lg-4 order-lg-1 no-print">
              <PrescriptionsComponent bookmarks={bookmarks} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PrescriptionsContainer;
