import React from 'react';
import { Redirect } from 'react-router'
import { get_illustration } from '../../../utils/illustration'
import UserGuideQuickNavComponent from './quicknav'
import UserGuideFooterLinksComponent from './footerlinks'
import { set_last_userguide_page } from '../../../utils/userguide'
/**
 * User guide pages.
 */
function UserGuideComponent({ match }) {
  // Get url id.
  const id = match.params.id
  // Load pages.
  const pages = JSON.parse(localStorage.getItem("user_guides_content"))
  const pages_list = JSON.parse(localStorage.getItem("user_guides_menu"))
  // No page to display, redirect to first item or element not exists.
  if (!id || !pages[id]) {
    const url = `/mode-emploi/${pages_list[0].path}`
    return (
      <Redirect to={url} />
    )
  }
  else {
    // Update userguide last page view.
    set_last_userguide_page(id)
    // Load page and display content.
    const page = pages[id]
    // Get prev & next user guide page.
    let prev = false
    let check_prev = true
    let next = false
    let next_title = ''
    let check_next = false

    pages_list.forEach( (element) => {
      // Check for previous element.
      if (check_prev && element.path !== id) {
        prev = `/mode-emploi/${element.path}`
      }
      else if (check_prev && element.path === id) {
        check_prev = false
      }
      // Check for next element
      if(check_next) {
        next = `/mode-emploi/${element.path}`
        next_title = element.title
        check_next = false
      }
      else if (!check_next && element.path === id) {
        check_next = true
      }
    })
    // Force next link to prescriptions page.
    if (!next) {
      next = '/prescriptions'
      next_title = 'Liste des prescriptions'
    }
    // Get illustration.
    const media = get_illustration(page.media, page.media_img)
    // Render HTML.
    return (
      <div className="page-container">
        <div className="user-guides-title-container-wrapper">
          <div className="user-guides-title-container">
            <UserGuideQuickNavComponent url={prev} type="prev" text="<" />
            <h1 className="page-title">Mode d'emploi</h1>
            <UserGuideQuickNavComponent url={next} type="next" text=">" />
          </div>
        </div>
        <div className="container-fluid page-container-inner">
          <div className="row">
            <div className="page-content-title col-md-6">
              <h2>
                { page.title }
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="page-content" dangerouslySetInnerHTML={{__html: page.content}} />
              Lire la suite :<br />
              <UserGuideQuickNavComponent url={next} type="content-next" text={next_title} />
            </div>
            <div className="col-md-6 no-print">
              { media }
            </div>
          </div>
          <div className="row no-print">
            <UserGuideFooterLinksComponent pages={pages_list} current={id} />
          </div>

        </div>

      </div>
    );
  }
}

export default UserGuideComponent;
