import React from 'react';
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga';
/**
 * User guide quick links.
 */
class UserGuideQuickNavComponent extends React.Component {

  constructor(props) {
    super(props)
    this.GAEvent = this.GAEvent.bind(this)
  }
  /**
   * GA Event tracking.
   */
  GAEvent() {
    const ga_type = this.props.type
    const options = JSON.parse(localStorage.getItem("options"));
    if (options.statistics === true) {
      ReactGA.event({
        category: 'QuickNav',
        action: 'Click',
        label: ga_type
      });
    }
  }

  render() {
    const url = this.props.url
    const type = `quick-link quick-link-${this.props.type}`
    const text = this.props.text

    if (!url) {
      return  <React.Fragment />
    }
    return (
      <Link to={url} className={type} onClick={this.GAEvent}>
        <span>{text}</span>
      </Link>
    );
  }
}

export default UserGuideQuickNavComponent;
