import React from 'react';
import { Redirect } from 'react-router'

import { get_illustration } from '../../../utils/illustration'

import AmValidatioDateComponent from './amvalidation'
import NotRefundedComponent from './notrefunded'
import PrescriptionQuestionComponent from './questions'
import SimilarComponent from './similar'
import PrescriptionBookmarkComponent from './bookmark'
import PrintComponent from './print'

/**
 * User guide pages.
 */
class PrescriptionComponent extends React.Component {

 render() {
   // Get url id.
   let prescription_id = this.props.prescription
   let prescription_check_title = ''
   let prescription_GA_value = ''
   // Get real ID (current id format = %d-%s)
   const prescriptions = JSON.parse(localStorage.getItem("prescriptions"))

   // Check if id exists.
   if (prescription_id) {
     const pos = prescription_id.indexOf("-");
     if (pos > -1) {
       // Check if title is correct.
       prescription_check_title = prescription_id.substring(pos+1)
       prescription_id = prescription_id.substring(0,pos)

       prescription_GA_value = `${prescription_id}-${prescription_check_title}`
     }
   }

   let prescription = false
   prescriptions.forEach( (item) => {
     if (item.id === prescription_id && item.title === prescription_check_title) {
       prescription = item
     }
   })

   // No prescription ID specified.
   if (!prescription_id) {
     return (
       <React.Fragment />
     )
   }
   // Specified ID does not match.
   if (!prescription && prescription_id) {
     return (
       <Redirect to="/prescriptions" />
     )
   }

   // Get illustration.
   const media = get_illustration(prescription.media, prescription.media_img)

   // Render HTML.
   return (
     <div>
       <div className="prescription-head">
         <h1>
           { prescription.title }
         </h1>
         <div className="wrapper-utils no-print">
           <PrescriptionBookmarkComponent pid={prescription.id} ptitle={prescription.title} prescriptionGA={prescription_GA_value} />
           <PrintComponent ptitle={prescription.title} prescriptionGA={prescription_GA_value}  />
         </div>
       </div>
       <div>
         <AmValidatioDateComponent date={ prescription.validation_date } />
         <NotRefundedComponent refunded={prescription.not_refunded} />
       </div>
       <PrescriptionQuestionComponent prescriptionGA={prescription_GA_value} />
       <SimilarComponent items={prescription.same_as} />
       <div>
         <div className="page-content" dangerouslySetInnerHTML={{__html: prescription.content}} />
       </div>
       <div className="illustration-prescription no-print">
         { media }
       </div>
     </div>
   );
  }
}
export default PrescriptionComponent;
