import React from 'react';
import {get_illustration, get_illustration_path} from '../../../utils/illustration'
import GLightbox from 'glightbox/dist/js/glightbox.min.js'

import 'glightbox/dist/css/glightbox.min.css'

/**
 * Illustration page.
 */
 class IllustrationsComponent extends React.Component {
   constructor(props) {
     super(props)
     this.illustrations_ids = JSON.parse(localStorage.getItem('illustrations_ids'))
   }

   componentDidMount() {
     GLightbox({
       'selector': 'glightbox',
     });
   }

   render() {
     return (
       <div className="illustrations-container">
         <div className="illustrations-title-container">
           <h1 className="page-title illustrations-title">Illustrations</h1>
         </div>
         <div className="row">
           {
             this.illustrations_ids.map((id, key) => {
               return(
                 <div className="col-sm-6 col-md-4 col-xl-3 illustration-row" key={key}>
                   <a className="glightbox" href={get_illustration_path(id)}>{get_illustration("1", id)}</a>
                 </div>
               )
             })
           }
         </div>
       </div>
     );
   }
}

export default IllustrationsComponent;
