import React from 'react';
import ReactGA from 'react-ga';
import { PWA_VERSION } from '../../../const'
import { syncAllData } from '../../../utils/sync'

class ToolsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      options: false,
      sync: 'waiting'
    }
    this.deleteAllBookmarks = this.deleteAllBookmarks.bind(this)
    this.tooglePrestationQuestion = this.tooglePrestationQuestion.bind(this)
    this.toogleStatisticTracking = this.toogleStatisticTracking.bind(this)
    this.syncData = this.syncData.bind(this)
    this.clearCache = this.clearCache.bind(this)
  }
  /**
   * Load user options.
   */
  componentDidMount() {
    this.setState({
      options: JSON.parse(localStorage.getItem("options"))
    })
  }

  /**
   * Force sync
   */
  syncData() {
    this.setState({
      sync: 'Running',
    })
    syncAllData()
    .then( (response) => {
      const options = JSON.parse(localStorage.getItem("options"))
      const current_timestamp = Math.ceil(+ new Date() / 1000)
      localStorage.setItem("options", JSON.stringify({...options, ...{last_sync: current_timestamp}}))
      this.setState({
        sync: 'waiting',
      })
    })
    // If an error occurs, display error message.
    .catch( error => {
      this.setState({
        sync: 'Failed',
      })
    })
  }


  /**
   * Delete all bookmarks.
   */
  deleteAllBookmarks() {
    var r = window.confirm("Confirmez vous la suppression de tous vos favoris ?");
    if (r) {
      let options = JSON.parse(localStorage.getItem("options"));
      options.bookmarks = []
      localStorage.setItem("options", JSON.stringify(options))
      this.setState({
        options: options
      })
    }
  }
  /**
   * Toogle prescription question display.
   */
  tooglePrestationQuestion() {
    let options = JSON.parse(localStorage.getItem("options"));
    options.display_prescription_question = !options.display_prescription_question
    localStorage.setItem("options", JSON.stringify(options))
    this.setState({
      options: options
    })
  }
  /**
   * Toogle statistics tracking user consent.
   */
  toogleStatisticTracking() {
    let options = JSON.parse(localStorage.getItem("options"));
    options.statistics = !options.statistics
    localStorage.setItem("options", JSON.stringify(options))
    this.setState({
      options: options
    })
  }
  /**
   * Clear cache.
   */
  clearCache() {
    caches.keys().then(function(cacheNames) {
      return Promise.all(
        cacheNames.filter(function(cacheName) {
          // Return true if you want to remove this cache,
          // but remember that caches are shared across
          // the whole origin
        }).map(function(cacheName) {
          console.log(cacheName)
          return caches.delete(cacheName);
        })
      );
    }).then( () => {
      console.log('Done')
      // window.location.reload();
    })
  }
  /**
   *
   */
  debugGaEvent() {
    ReactGA.event({
      category: 'debug',
      action: 'Clicked Link',
    });
  }

  getLastSync() {
    const options = JSON.parse(localStorage.getItem("options"))
    const date = new Date(options.last_sync*1000);
    const d = "0" + date.getDate();
    const month = "0" + (date.getMonth() + 1);
    const y = date.getFullYear();
    const h = date.getHours();
    const m = "0" + date.getMinutes();
    const s = "0" + date.getSeconds();
    console.log(month)
    return d.substr(-2) + '/' + month.substr(-2) + '/' + y + ' ' + h + ':' + m.substr(-2) + ':' + s.substr(-2);
  }

  /**
   * Render HTML.
   */
  render() {
    const { sync, options } = this.state
    const version = PWA_VERSION
    if (!options) {
      return <React.Fragment />
    }
    // Will display time in 10:30:23 format
    const last_sync = this.getLastSync()


    //
    // Render full display.
    //
    return (
      <div className="tools-container">
        <div className="tools-title-container">
          <h1 className="page-title tools-title">Paramètres</h1>
        </div>
        <h3>Favoris</h3>
        { options.bookmarks.length > 0 ? (
          <div>
            Votre liste de favoris contient {options.bookmarks.length} element{options.bookmarks.length > 0 && 's'}.<br />
            <button className="btn" onClick={this.deleteAllBookmarks}>
              Supprimer tous les favoris
            </button>
          </div>
        ) : (
          <div>
            Votre liste de favoris est vide.
          </div>
        )}
        <h3>Prestation</h3>
        { options.display_prescription_question ? (
          <div>
            <p>
              Par défaut, les questions à se poser avant de rédiger une prescription sont affichées.
            </p>
            <button className="btn" onClick={this.tooglePrestationQuestion}>
              Ne pas afficher les questions par défaut
            </button>
          </div>
        ) : (
          <div>
            <p>
              Par défaut, les questions à se poser avant de rédiger une prescription ne sont pas affichées.
            </p>
            <button className="btn" onClick={this.tooglePrestationQuestion}>
              Afficher les questions par défaut
            </button>
          </div>
        )}
        <h3>Statistiques</h3>
        { options.statistics ? (
          <div>
            <p>
              Vous avez accepté de partager vos statistiques d'utilisation de l'application et nous vous en remercions :
              ces données nous permettent d'améliorer votre expérience utilisateur.
            </p>
            <button className="btn" onClick={this.toogleStatisticTracking}>
              Ne plus partager ces données
            </button>
          </div>
        ) : (
          <div>
            <p>
              Vous n'avez pas accepté de partager vos statistiques d'utilisation de l'application et nous respectons ce choix.
            </p>
            <button className="btn" onClick={this.toogleStatisticTracking}>
              Partager ces données et nous permettre d'améliorer votre expérience utilisateur
            </button>
          </div>
        )}
        <h3>Synchronisation</h3>
        <p>
          Date de dernière synchronisation des données : {last_sync}.
        </p>
        { (sync === 'waiting' && navigator.onLine)  &&
          <button className="btn" onClick={this.syncData}>
            Synchroniser les données
          </button>
        }
        { sync === 'Running' &&
          <span>Synchronisation en cours</span>
        }
        <h3>Version</h3>
        <p>
          {version}
        </p>
        {
          /*
          <button className="btn" onClick={this.clearCache}>
          Vider le cache de l'application
          </button>
          */
        }
      </div>
    )
  }
}

export default ToolsContainer;
