// import React from 'react';
// import { Redirect } from 'react-router-dom'

export function set_last_userguide_page(id) {
  // Check if all userguide have been read.
  let options = JSON.parse(localStorage.getItem("options"))
  // Userguide has allready be readed.
  if (options.userguide === true) {
    return
  }

  // Check if current id is last userguide page id.
  const pages_list = JSON.parse(localStorage.getItem("user_guides_menu"))
  if (id === pages_list[pages_list.length - 1].path) {
    const userguide_option = {
      userguide: true,
    }
    localStorage.setItem("options", JSON.stringify({...options, ...userguide_option}))
    let body = document.getElementById('body')
    body.className = ""
  }
  else {
    const userguide_option = {
      userguide: id,
    }
    localStorage.setItem("options", JSON.stringify({...options, ...userguide_option}))
  }
}
/*
export function check_userguide_reading() {

  const options = JSON.parse(localStorage.getItem("options"))
  // Check current path and redirect to user guide if user has not read all userguide pages.
  if (options.userguide !== false && options.userguide !== true && `/mode-emploi/${options.userguide}` !== document.location.pathname) {
    // Redirect user to last userguide page view.
    document.location = `/mode-emploi/${options.userguide}`
  }
}
*/
