import React from 'react';
/**
 * User guide pages.
 */
function NotRefundedComponent( { refunded } ) {
  if (refunded === '1') {
    return (
      <div className="prescription-not-refunded">Soins non remboursés</div>
    );
  }
  else {
    return <React.Fragment />
  }
}

export default NotRefundedComponent;
