import React from 'react';
import {Link} from "react-router-dom";

import logo from '../../../img/logo-urps-il-bfc.png'

class FooterContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  /**
   * Render HTML.
   */
  render() {
    // Load footer links.
    const pages = JSON.parse(localStorage.getItem('pages_menu'))
    //
    // Render full display.
    //
    return (
      <footer className="footer-container">
        <div className="container">
          <div className="row">
            <ul className="col-lg-9 no-print">
              {
                pages.map((object, i) => {
                  const url = `/page/${object.path}`
                  return (
                    <React.Fragment key={i}>
                      <li>
                        <Link to={url}>{object.title}</Link>
                      </li>
                    </React.Fragment>
                  )
                })
              }
            </ul>
            <div className="img-urps col-lg-3">
              <img src={logo} alt="" />
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default FooterContainer;
