import React from 'react';
import { Redirect } from "react-router-dom";

class HomeContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  /**
   * Render HTML.
   */
  render() {
    const location = this.props.location
    let options = JSON.parse(localStorage.getItem("options"))
    let redirect = ''
    // Get last page view.
    if (options.last_page && location.pathname === '/' && location.search !== '?hp') {
      redirect = <Redirect to={options.last_page} />
    }
    // If user has read all user guide, go to prescription
    else if (options.userguide === true) {
      redirect = <Redirect to="/prescriptions" />
    }
    // else, go to user guide.
    else {
      redirect = <Redirect to="/mode-emploi" />
    }
    // Render.
    return (
      <div className="home-container">
        { redirect }
      </div>
    )
  }
}

export default HomeContainer;
