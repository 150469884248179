import React from 'react';

class LoadingComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  /**
   * Render HTML.
   */
  render() {
    const op = this.props.op
    const error = this.props.error

    const container_class = (error) ? 'loading-content' : 'loading-content error-message'
    //
    // Render full display.
    //
    return (
      <div className="loading-container">
        <div className={ container_class }>
          <h1>Les indispensables de la prescription de soins infirmiers</h1>
          <p>Ce mémo est une initiative originale de l'Union Régionale des Professionnels de Santé Infirmiers Libéraux de Bourgogne-Franche-Comte qui représente au quotidien 3.200 infirmiers libéraux exerçant dans la région.</p>
          <h2>
            Chargement en cours
          </h2>
          <p>
            { op }
          </p>
        </div>
      </div>
    )
  }
}

export default LoadingComponent;
