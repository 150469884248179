import axios from 'axios'
import {
  API_PRESTATIONS,
  API_ILLUSTRATIONS,
  API_PAGES,
  API_USER_GUIDES,
  API_AXIOS_PARAMS,
  API_MEDIA
} from '../const'
import removeAccents from 'remove-accents'

/**
 * HTTP GET request - Synchronize prestations.
 */
function sync_get_prestations() {
  return axios.get(API_PRESTATIONS, API_AXIOS_PARAMS);
}
/**
 * HTTP GET request - Synchronize illustrations.
 */
function sync_get_illustrations() {
  return axios.get(API_ILLUSTRATIONS, API_AXIOS_PARAMS);
}
/**
 * HTTP GET request - Synchronize pages.
 */
function sync_get_pages() {
  return axios.get(API_PAGES, API_AXIOS_PARAMS);
}
/**
 * HTTP GET request - Synchronize user guides.
 */
function sync_get_user_guides() {
  return axios.get(API_USER_GUIDES, API_AXIOS_PARAMS);
}
/**
 * Local storage - Save prestations.
 */
function sync_save_prestations(elements) {
  let storage = []
  elements.forEach( item => {
    // Sanitize keywords (used in user filtering)
    let keywords = removeAccents(item.title.toLowerCase())
    if (item.keywords) {
      keywords = `${keywords} ${item.keywords}`
    }
    item.keywords = keywords
    item.content = item.content.replace('src="/sites/default/files/', `src="${API_MEDIA}/sites/default/files/`)
    storage.push(item)
  })

  localStorage.setItem('prescriptions', JSON.stringify(storage))
}
/**
 * Local storage - Save illustrations.
 */
function sync_save_illustrations(elements) {
  let storage = {}
  let counter = 0
  // Store ids in array to display random illustration.
  let storage_ids = []
  elements.forEach( item => {
    storage[`i${item.id}`] = item.image
    storage_ids.push(item.id)
  })
  storage['counter'] = counter
  localStorage.setItem('illustrations', JSON.stringify(storage))
  localStorage.setItem('illustrations_ids', JSON.stringify(storage_ids))
}
/**
 * Local storage - Save pages.
 */
function sync_save_pages(elements) {
  let storage_content = {}
  let storage_menu = []
  elements.forEach( item => {
    const path = item.path.substring(1)
    storage_content[path] = item
    // Update content if image founded.
    let pattern_regex = new RegExp('src="/sites/default/files/', 'g')
    storage_content[path].content = storage_content[path].content.replace(pattern_regex, `src="${API_MEDIA}/sites/default/files/`)

    storage_menu.push({
      title: item.title,
      path: path
    })
  })
  localStorage.setItem('pages_content', JSON.stringify(storage_content))
  localStorage.setItem('pages_menu', JSON.stringify(storage_menu))
}
/**
 * Local storage - Save user guides.
 */
function sync_save_user_guides(elements) {
  let storage_content = {}
  let storage_menu = []
  elements.forEach( item => {
    const path = item.path.substring(1)
    storage_content[path] = item
    storage_content[path].content = storage_content[path].content.replace('src="/sites/default/files/', `src="${API_MEDIA}/sites/default/files/`)
    storage_menu.push({
      title: item.title,
      path: path
    })
  })
  localStorage.setItem('user_guides_content', JSON.stringify(storage_content))
  localStorage.setItem('user_guides_menu', JSON.stringify(storage_menu))
}
/**
 * Synchronize all data.
 */
export const syncAllData = function () {
  return new Promise(
    (resolve, reject) => {
      axios.all([sync_get_prestations(), sync_get_illustrations(), sync_get_pages(), sync_get_user_guides()])
      .then(axios.spread(function (prestations, illustrations, pages, user_guides) {
        // Both requests are now complete
        let error = false
        let error_messages = []
        //
        // Quick & Ugly.. Sorry.
        //
        // Check & save prestations.
        if (prestations.status !== 200) {
          error = true
          error_messages.push('Erreur lors de la synchronisation des prescriptions')
        }
        else {
          sync_save_prestations(prestations.data)
        }
        // Check & save illustrations.
        if (illustrations.status !== 200) {
          error = true
          error_messages.push('Erreur lors de la synchronisation des illustrations')
        }
        else {
          sync_save_illustrations(illustrations.data)
        }
        // Check & save pages.
        if (pages.status !== 200) {
          error = true
          error_messages.push('Erreur lors de la synchronisation des pages éditoriales')
        }
        else {
          sync_save_pages(pages.data)
        }
        // Check & save user guides.
        if (user_guides.status !== 200) {
          error = true
          error_messages.push("Erreur lors de la synchronisation du mode d'emploi")
        }
        else {
          sync_save_user_guides(user_guides.data)
        }
        // If an error occurs, reject promise.
        if (error) {
            reject(error_messages)
        }
        else {
          // Resolve promise.
          resolve()
        }
      }));
    }
)};
