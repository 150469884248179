import React, { Component } from 'react';
import {
  BrowserRouter,
  Route
} from "react-router-dom";

import BurgerMenuComponent from './react/components/burgermenu';
import MainMenuComponent from './react/components/mainmenu';
import {Link} from "react-router-dom";
import logo from './img/logo-urps-il-bfc.png';
import FooterContainer from './react/container/footer'
// import SyncContainer from './react/container/sync'
import PrescriptionsContainer from './react/container/prescriptions'
import ToolsContainer from './react/container/tools'
import HomeContainer from './react/container/home'
import UserGuideComponent from './react/components/userguide'
import PageComponent from './react/components/page'
import IllustrationsComponent from './react/components/illustrations'
import ChangePage from './react/changepage'
// import MobileDetect from 'mobile-detect';

// import { syncAllData } from './utils/sync'
// import {LS_LAST_SYNC_TS} from './const'

class AppRouter extends Component {
  /**
   *
   */
  constructor(props) {
    super(props)
    this.state = {
      menuIsOpen: false,
    }
    this.toogleMenu = this.toogleMenu.bind(this)
    this.updateApp = this.updateApp.bind(this)
  }
  /**
   *
   */
  toogleMenu () {
    const burger_menu_status = (document.getElementById('burgerMenu').getAttribute('attr-open') === '1')

    const burgerMenu = document.getElementById('burgerMenu')
    if (burger_menu_status) {
      burgerMenu.setAttribute('attr-open', '0')
      burgerMenu.className = 'burgerIcon'
      document.getElementById('mainMenu').className = 'main-menu closed'
    }
    else {
      burgerMenu.setAttribute('attr-open', '1')
      burgerMenu.className = 'burgerIcon open'
      document.getElementById('mainMenu').className = 'main-menu open'
    }
  }
  /**
   * Update notification -> click.
   */
  updateApp() {
    window.location.reload();
    /*
    const md = new MobileDetect(window.navigator.userAgent);
    if (md.phone() || md.tablet()) {
      window.location.reload();
    }
    else {
      this.clearCacheAndUpdate()
    }
    */
  }
  /**
   * Update - desktop.
   */
  clearCacheAndUpdate() {
    caches.keys().then(function(cacheNames) {
      return Promise.all(
        cacheNames.filter(function(cacheName) {
          // Return true if you want to remove this cache,
          // but remember that caches are shared across
          // the whole origin
        }).map(function(cacheName) {
          console.log(cacheName)
          return caches.delete(cacheName);
        })
      );
    }).then( () => {
      window.location.reload();
    })
  }
  /**
   *
   */
  render() {
    let { menuIsOpen } = this.state

    return (
      <div id="AppContainer" className="App">
        <BrowserRouter>
          <ChangePage>
            <div className="AppRouter">
              <div className="container-fluid">
                <div id="update_notification" onClick={this.updateApp}>Une nouvelle version de l'application est disponible&nbsp;!<br />Mettre à jour l'application.</div>
                <div className="row">
                  <div className="col-lg-9 order-lg-2">
                    <header className="header-container">
                      <div className="banner">
                        <div onClick={this.toogleMenu}>
                          <BurgerMenuComponent isOpen={menuIsOpen} />
                        </div>
                        <h1>
                          <Link to="/?hp">
                            Les indispensables de la prescription de soins infirmiers
                          </Link>
                        </h1>
                        <div className="logo-urps">
                          <img src={logo} alt="" />
                        </div>
                      </div>
                      {/* Menu mobile */}
                      <MainMenuComponent isOpen={menuIsOpen} />
                    </header>
                    <section>
                      <Route exact path="/" component={HomeContainer} />
                      <Route exact path="/prescriptions" component={PrescriptionsContainer} />
                      <Route exact path="/prescriptions/:id" component={PrescriptionsContainer} />
                      <Route exact path="/prescriptions/:id/:favoris" component={PrescriptionsContainer} />
                      <Route exact path="/parametres" component={ToolsContainer} />
                      <Route exact path="/mode-emploi" component={UserGuideComponent} />
                      <Route exact path="/illustrations" component={IllustrationsComponent} />
                      <Route exact path="/mode-emploi/:id" component={UserGuideComponent} />
                      <Route exact path="/page/:id" component={PageComponent} />
                    </section>
                    <FooterContainer/>
                  </div>
                  <div className="col-lg-3 order-lg-1 menu-left">
                    {/* Menu Desktop*/}
                    <MainMenuComponent isOpen={true} />
                  </div>
                </div>
              </div>
              { /*<SyncContainer /> */ }
            </div>
          </ChangePage>
        </BrowserRouter>
      </div>
    );
  }
}

export default AppRouter;
