import React from 'react';
import { NavLink } from 'react-router-dom'
import removeAccents from 'remove-accents'
import ReactGA from 'react-ga';
import MobileDetect from 'mobile-detect';
/**
 * User guide pages.
 */
class PrescriptionsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialItems: this.setInitialItems(props.bookmarks),
      items: [],
      isMobile: false,
    }
    this.filterPosition = false
    this.filterList = this.filterList.bind(this)
    this.filterFocus = this.filterFocus.bind(this)
  }
  /**
   * On load, initialize default items.
   */
  componentDidMount() {
    this.setItems()
    const md = new MobileDetect(window.navigator.userAgent);
    if (md.phone() || md.tablet()) {
      this.setState({
        'isMobile': true,
      })
    }
  }
  /**
   * Filter prescriptions list when user is doinf a research.
   */
  filterList(event) {
    this.setItems(event.target.value)
  }
  /**
   * Click / Focus on filter
   */
  filterFocus() {
    const {isMobile} = this.state
    if (isMobile) {
      // Get filter position.
      const filter = document.getElementById('prescription-filter')
      const position = filter.getBoundingClientRect()
      if (this.filterPosition === false) {
        this.filterPosition = position.y
      }
      window.scrollTo(0, position.y)
    }
  }

  /**
   * Filtering method.
   */
  setItems(search='') {
    const initial_items = this.state.initialItems
    let items = initial_items
    let keywords = search.trim()
    // If search is not empty, filter prescriptions.
    if (keywords !== '') {
      // Scroll to input.
      // window.scrollTo(0, 0)

      // Sanitize user search
      keywords = removeAccents(keywords.toLowerCase())
      let counter = 0;
      items = items.filter(function(item){
        const result = item.keywords.search(keywords)
        if (result !== -1) {
          counter++
          return true
        }
        return false
      });
      // Track GA event.
      let user_options = JSON.parse(localStorage.getItem("options"));
      if (user_options.statistics === true) {
        ReactGA.event({
          category: 'Search',
          action: 'Filter',
          value: counter,
          label: keywords,
        });
      }
    }
    this.setState({
      items: items
    })
  }
  /**
   * Load prescriptions from local storate.
   */
  setInitialItems(bookmarks=false) {
    const prescriptions = JSON.parse(localStorage.getItem("prescriptions"))
    const options = JSON.parse(localStorage.getItem("options"))

    let items = []
    prescriptions.forEach( (element) => {
      let push = true
      // Take care of bookmarked prescriptions.
      if (bookmarks && options.bookmarks.indexOf(element.id) === -1) {
        push = false
      }
      // Add prescription to list.
      if (push) {
        items.push({
          'id': element.id,
          'title': element.title,
          'keywords': element.keywords
        })
      }
    })
    return items
  }

  /**
   * Render HTML.
   */
  render() {
    let items = this.state.items
    const bookmarks = this.props.bookmarks
    const attr_bookmarks = (this.props.bookmarks) ? '1' : '0'
    // Quick fix : display bookmarks after bookmarks a prescription.
    if (bookmarks) {
      items = this.setInitialItems(true)
    }

    return (
      <React.Fragment>
        <div className="prescriptions-filter">
          <label htmlFor="prescriptions-filter" id="prescription-filter">
            Rechercher une prescription
          </label>
          <input id="prescriptions-filter"
            type="text"
            placeholder="Rechercher une prescription"
            onClick={this.filterFocus}
            onFocus={this.filterFocus}
            onChange={this.filterList} />
        </div>
        <ul className="prescriptions-list"
          id="prescriptions-list"
          attr-bookmarks={attr_bookmarks}>
          {
            items.map( (item, key) => {
              // const uri = encodeURI(item.title)
              const uri = item.title
              const path = `${item.id}-${uri}`

              let url = `/prescriptions/${path}`
              if (bookmarks) {
                url = `/prescriptions/${path}/favoris`
              }
              const id = `prescription-${item.id}`
              return (
                <li key={key} id={id}>
                  <NavLink activeClassName='is-active' to={url}>
                    {item.title}
                  </NavLink>
                </li>
              )
            })
          }
          { /* No results */}
          { items.length === 0 &&
            <div>Aucune prescription ne correspond à votre requête.</div>
          }
        </ul>
      </React.Fragment>
    );
  }
}

export default PrescriptionsComponent;
