import React from 'react';
import { Redirect } from 'react-router'
/**
 * User guide pages.
 */
function UserGuideComponent({ match }) {
  // Get url id.
  const id = match.params.id
  // Load pages.
  const pages = JSON.parse(localStorage.getItem("pages_content"))
  // No page to display, redirect to first item or element not exists.
  if (!id || !pages[id]) {
    const pages = JSON.parse(localStorage.getItem("pages_menu"))
    const url = `/page/${pages[0].path}`
    return (
      <Redirect to={url} />
    )
  }
  else {
    // Load page and display content.
    const page = pages[id]

    return (
      <div className="page-container">
        <div className="page-title-container">
          <h1 className="page-title">{page.title}</h1>
        </div>
        <div className="page-content" dangerouslySetInnerHTML={{__html: page.content}} />
      </div>
    );
  }
}

export default UserGuideComponent;
