import React from 'react';
import { Link } from 'react-router-dom'
/**
 * User guide pages.
 */
function SimilarComponent( { items } ) {
  if (!items || items.length === 0) {
    return <React.Fragment />
  }
  else {
    const same_as = items
    const prescriptions = JSON.parse(localStorage.getItem("prescriptions"))
    let similar = []
    prescriptions.forEach( (prescription) => {
      if (same_as.indexOf(prescription.id) !== -1) {
        similar.push({
          id: prescription.id,
          title: prescription.title,
          url: `/prescriptions/${prescription.id}-${prescription.title}`
        })
      }
    })

    return (
      <div className="similar-prescriptions">
        <p>Cette prescription est identique aux prescriptions suivantes :</p>
        {
          similar.map((element, key) => {
            return(
              <Link key={key} to={element.url}>
                {element.title}
              </Link>
            )
          })
        }
      </div>
    );
  }
}

export default SimilarComponent;
