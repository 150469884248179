import React from 'react';
import UserGuideQuickNavComponent from './quicknav'
/**
 * User guide footer links.
 */
function UserGuideFooterLinksComponent(props) {
  const pages = props.pages
  const current = props.current

  return (
    <div className="row footer-quick-link-container">
      { pages.map( (element, key) => {
        const url = `/mode-emploi/${element.path}`
        const type = (element.path === current) ? 'footer footer-current' : 'footer'
        return (
          <div key={key} className="col-6 col-md-3 d-lg-none">
            <UserGuideQuickNavComponent url={url} type={type} text={element.title} />
          </div>
        )
      }) }
    </div>
  );
}

export default UserGuideFooterLinksComponent;
