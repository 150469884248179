import React from 'react';
import { ILLUSTATIONS_HOST } from '../const'

export function get_illustration_path(id) {
  const illustrations = JSON.parse(localStorage.getItem('illustrations'))
  if (illustrations[`i${id}`]) {
    let image = illustrations[`i${id}`]
    let src = `${ILLUSTATIONS_HOST}${image}`
    return src
  }
  return false
}

export function get_illustration(display, id) {
  let image = false
  // If image display is activated.
  if (display > 0) {
    const illustrations = JSON.parse(localStorage.getItem('illustrations'))
    // Display specified image.
    if (display === "1" && illustrations[`i${id}`]) {
      image = illustrations[`i${id}`]
    }
    // Display random image.
    else if (display === "2") {
      const illustrations_ids = JSON.parse(localStorage.getItem('illustrations_ids'))
      const _id = illustrations_ids[Math.floor(Math.random()*illustrations_ids.length)];
      if (illustrations[`i${_id}`]) {
        image = illustrations[`i${_id}`]
      }
    }
  }
  // No image.
  if (image === false) {
    return (
      <div />
    )
  }
  // Image display.
  else {
    const src = `${ILLUSTATIONS_HOST}${image}`
    return (
      <img src={src} alt="" className="illustration" />
    )
  }
}

export default {get_illustration, get_illustration_path}
