const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // recette-urps-memo-pwa.i-com.fr
    window.location.hostname === 'recette-urps-memo-pwa.i-com.fr' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

let API_HOST
let API_SYNC_TIMEOUT_MS
let ILLUSTRATIONS_HOST_DOMAIN

if (isLocalhost) {
  API_HOST = 'https://recette-urps-memo-admin.i-com.fr'
  ILLUSTRATIONS_HOST_DOMAIN = 'https://recette-urps-memo-admin.i-com.fr'
  API_SYNC_TIMEOUT_MS = 2000
}
else {
  API_HOST = 'https://admin.prescription-soins-idel.fr'
  ILLUSTRATIONS_HOST_DOMAIN = 'https://admin.prescription-soins-idel.fr'
  API_SYNC_TIMEOUT_MS = 5000
}

export const API_AXIOS_PARAMS = {
  timeout: 5000,
}

export const API_SYNC_TIMEOUT = API_SYNC_TIMEOUT_MS

export const PWA_VERSION = '1.1'
export const API_URL = `${API_HOST}/`
export const API_MEDIA = ILLUSTRATIONS_HOST_DOMAIN
export const API_PRESTATIONS = `${API_HOST}/api/v1/prescriptions?_format=json`
export const API_USER_GUIDES = `${API_HOST}/api/v1/user-guides?_format=json`
export const API_PAGES = `${API_HOST}/api/v1/pages?_format=json`
export const API_ILLUSTRATIONS = `${API_HOST}/api/v1/illustrations?_format=json`
export const API_LAST_UPDATE = `${API_HOST}/api/v1/lastupdate?_format=json`

export const ILLUSTATIONS_HOST = ILLUSTRATIONS_HOST_DOMAIN

export const API_DEFAULT_REQUEST_PARAMS = {timeout: 10000,}
export const SYNC_STATUS_WAITING = 'SYNC_STATUS_WAITING'
export const SYNC_STATUS_RELOAD = 'SYNC_STATUS_RELOAD'
export const SYNC_STATUS_RUNNING = 'SYNC_STATUS_RUNNING'
