import React, { Component } from 'react';
// import SyncContainer from './react/container/sync'
import LoadingComponent from './react/components/loading'
import { syncAllData } from './utils/sync'
import AppRouter from './AppRouter'
import logo from './img/logo-urps-il-bfc.png';

class App extends Component {
  /**
   *
   */
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      error: false,
      op: "",
      is_sync: false,
      hasUpdate: undefined,
      last_page: '/',
    }
    this.cookieConsent = this.cookieConsent.bind(this)
  }
  /**
   *
   */
  componentDidMount() {
    const loading = this.state.loading
    if (loading ) {
      this.appLoading()
    }
  }

  cookieConsent() {
    // Local options.
    const options = JSON.parse(localStorage.getItem("options"))
    // Merge current options and default options.
    localStorage.setItem("options", JSON.stringify({...options, ...{statistics: true}}))
    this.setState({
      'loading': false,
      'is_sync': true,
    })
  }

  /**
   * Init application loading controls.
   */
  appLoading() {
    this.setState({
      'op': "Initialisation de l'application",
    })
    // Check if localstorage is supported by browser.
    if (this.checkLocalStorageSupport()) {
      // Init options if not exists.
      this.initOption()
      // Check if user has read userguide.
      this.checkUserGuideReading()
      // Next step, sync or not, that is the question.
      this.appLoadingSync()
    }
    // Local storage is not supported. Display error message.
    else {
      this.setState({
        'error': true,
        'op': "Votre navigateur ne permet pas l'execution de cette application. Essayer avec un autre navigateur web.",
      })
    }
  }
  /**
   * Init user options for the application.
   */
  initOption() {
    // Default application options.
    const default_options = {
      last_sync: 0,
      display_prescription_question: true,
      bookmarks: [],
      statistics: null,
      userguide: false,
    }
    // Local options.
    let options = JSON.parse(localStorage.getItem("options"))
    // If null, create an object to merge it.
    if (!options) {
      options = {}
    }
    // Merge current options and default options.
    localStorage.setItem("options", JSON.stringify({...default_options, ...options}))
  }
  /**
   * Check if user has read all user guide pages.
   * If not, add class to body to hide some DOM elements.
   */
  checkUserGuideReading() {
    /*
    const options = JSON.parse(localStorage.getItem("options"))
    if (options.userguide !== true) {
      let body = document.getElementById('body')
      var index = body.className.indexOf('userguide');
      if (index === -1) {
        body.className = "userguide_first"
      }
    }
    */
  }
  /**
   * Check if local storage is supported.
   */
  checkLocalStorageSupport(){
    var test = 'test';
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch(e) {
      return false;
    }
  }
  /**
   * Check if application is allready synchronized.
   * If not, launch 1st synchronization.
   */
  appLoadingSync() {
    const options = JSON.parse(localStorage.getItem("options"))
    const lastSync = options.last_sync
    const current_timestamp = Math.ceil(+ new Date() / 1000)
    // No sync.
    if (!lastSync) {
      if (navigator.onLine) {
          this.appSyncData()
      }
      else {
          this.setState({
            'error': true,
            'op': "Vous devez être connecté à Internet lors de la première exécution de l'application."
          })
      }
    }
    // Force sync if online and last sync too old. (1 day)
    else if(lastSync < (current_timestamp - 86400) && navigator.onLine) {
      this.appSyncData()
    }
    // Last sync up to date.
    else {
      this.appLoadingSyncComplete()
    }
  }
  /**
   * Reset states on wync complete.
   */
  appLoadingSyncComplete() {
    // Reset states.
    this.setState({
      'loading': false,
      'is_sync': true,
    })
  }
  /**
   * Run synchronization.
   */
  appSyncData() {
    this.setState({
      'loading': true,
      'op': "Synchronisation des données",
    })
    // Synchronize all data.
    syncAllData()
    .then( (response) => {
      const options = JSON.parse(localStorage.getItem("options"))
      const current_timestamp = Math.ceil(+ new Date() / 1000)
      localStorage.setItem("options", JSON.stringify({...options, ...{last_sync: current_timestamp}}))
      this.appLoadingSyncComplete()
    })
    // If an error occurs, display error message.
    .catch( error => {
      this.setState({
        'error': true,
        'op': "La synchronisation des données a échouée. Votre terminal est-il bien connecté à Internet ?",
      })
    })
  }
  /**
   * Check if current user is a search bot (like Google).
   *
   * We dont force bot to acept Cookies.
   */
  isUseraSearchBot() {
    return /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent)
  }
  /**
   *
   */
  render() {
    const loading = this.state.loading
    const op = this.state.op
    const error = this.state.error
    const options = JSON.parse(localStorage.getItem("options"))


    let content = ''
    // Loading screen.
    if (loading) {
      content = <LoadingComponent op={op} error={error} />
    }
    else if (options.statistics === null && !this.isUseraSearchBot()) {
      // Cookie consent
      content = <div>
        <div className="App AppDisclamer">
          <div className="AppRouter">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-9 order-lg-2">
                  <header className="header-container">
                    <div className="banner">
                      <h1>
                        Les indispensables de la prescription de soins infirmiers
                      </h1>
                      <div className="logo-urps">
                        <img src={logo} alt="" />
                      </div>
                    </div>
                  </header>
                  <section>
                    <p>
                      Cette application utilise des cookies pour réaliser des statistiques d'utilisation afin d'améliorer votre expérience utilisateur.
                    </p>
                    <p>
                      En poursuivant votre navigation, vous acceptez l’utilisation de ces cookies.
                    </p>
                    <p>
                      <button id="cookieConsent" onClick={this.cookieConsent}>
                        Accéder à l'application
                      </button>
                    </p>
                    <p>
                      Vous pouvez à tout moment désactiver cette fonctionnalité depuis les paramètres de l'application.
                    </p>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    // App display.
    else {
      content = <AppRouter />
    }

    return (
      <div className="App">
        <div id="focus_content" tabIndex="-1"></div>
        {content}
      </div>
    );
  }
}

export default App;
