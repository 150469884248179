import React from 'react';
import ReactGA from 'react-ga';
/**
 * User guide pages.
 */
class PrintComponent extends React.Component {

  constructor(props) {
    super(props)
    this.printPrescription = this.printPrescription.bind(this)
  }
  /**
   *
   */
  printPrescription() {
    const prescription = this.props.ptitle
    const options = JSON.parse(localStorage.getItem("options"));
    if (options.statistics === true) {
      ReactGA.event({
        category: 'Print',
        action: 'Click',
        label: prescription
      });
    }
    window.print()
  }

  render() {
    return (
      <button className="print-icon" onClick={this.printPrescription} />
    )
  }
}

export default PrintComponent;
